/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:e900db4a-0a5e-4418-bce9-9ceabee3a717",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_w6fCVaEuD",
    "aws_user_pools_web_client_id": "5j23v94mj757h32o8ttjk2a9ut",
    "oauth": {}
};


export default awsmobile;
